<template>
  <div class="flex h-full">
    <!-- Static sidebar for desktop -->
    <div class="hidden lg:flex lg:flex-shrink-0">
      <div class="flex w-16 flex-col">
        <div
          class="flex min-h-0 flex-1 flex-col overflow-y-auto bg-secondary-darker"
        >
          <div class="flex-1">
            <div
              class="flex items-center justify-center bg-secondary-darker pt-4"
            >
              <NuxtLink :to="logoLink">
                <SharedBasicsDefaultImage
                  class="h-8 px-3 mt-3"
                  img-classes="rounded-full"
                  source="/shield_negative.svg"
                  alt="SEC AUDITOR logo"
                />
              </NuxtLink>
            </div>
            <hr class="w-3/4 border-secondary-light mx-auto my-6">
            <nav
              aria-label="Sidebar"
              class="flex flex-col items-center space-y-6"
            >
              <!-- support icon (only sp) -->
              <BIconChatLeft
                class="h-6 w-6 mx-auto text-common-white hover:text-white cursor-pointer"
                @click="supportIconClick"
              />

              <!-- settings icon -->
              <NuxtLink class="mx-auto cursor-pointer " to="/settings">
                <BIconPersonGear
                  class="h-6 w-6 mx-auto text-common-white hover:text-white cursor-pointer"
                />
              </NuxtLink>
            </nav>
          </div>
          <div class="flex flex-shrink-0 pb-2 flex-col">
            <div class="mx-auto cursor-pointer mb-2">
              <popper
                placement="right"
                hover
                content="Dokumentation"
              >
                <NuxtLink class="w-full flex-shrink-0 " to="https://sec-auditor.com/docs/" target="_blank">
                  <BIconQuestionCircle
                    class="mx-auto text-secondary-light w-6"
                  />
                </NuxtLink>
              </popper>
            </div>

            <div class="mx-auto cursor-pointer w-6" @click="signOutUser">
              <popper
                placement="right"
                hover
                content="Logout"
              >
                <BIconBoxArrowLeft
                  class="mx-auto text-secondary-light w-6"
                />
              </popper>
            </div>
            <hr class="border-t border-secondary-light w-1/3 mx-auto my-2">
            <NuxtLink class="w-full flex-shrink-0 text-center" to="https://sec-auditor.com/changelog/" target="_blank">
              <p class="mx-auto text-secondary-light cursor-pointer text-[11px]">
                {{ envVars.public.VERSION }}
              </p>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>

    <div class="flex min-w-0 flex-1 flex-col overflow-hidden">
      <main class="flex flex-1 overflow-hidden">
        <!-- Primary column -->
        <slot name="primarySection" />

        <!-- Secondary column (hidden on smaller screens) -->
        <slot name="secondarySection" />
      </main>
    </div>

    <SupportModal
      findings=""
      titel="Support"
      :show="showSupportModal"
      @on-cancel="supportIconClick(false)"
      @on-ok="supportIconClick(false)"
    />
  </div>
</template>

<script setup>
import {
  BIconBoxArrowLeft,
  BIconPersonGear,
  BIconChatLeft,
  BIconQuestionCircle
} from 'bootstrap-icons-vue'
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import { useCurrentUserSession } from '~/composables/states'
import SupportModal from '~/components/shared/modals/SupportModal.vue'
import { useCurrentServiceprovider } from '~/composables/serviceprovider'

defineComponent({
  components: {
    Popper
  }
})

const { signOut } = useAuth()
const { $api, $sentry } = useNuxtApp()
const { isServiceproviderUser, isAdminUser } = useCurrentUserSession()
const { serviceprovider, initializeServiceprovider } =
  useCurrentServiceprovider()
const { getCurrentClientUUID } = useCurrentClient()
const { status, data: session, signIn } = useAuth()
const route = useRoute()
const envVars = useRuntimeConfig()

const showSupportModal = ref(false)

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | SEC AUDITOR` : 'SEC AUDITOR'
  }
})

// first check session status
if (status.value === 'unauthenticated') {
  await signIn('keycloak', { callbackUrl: '/' })
}

if (isServiceproviderUser) {
  storeServiceprovider()
}

function supportIconClick (state = null) {
  if (state) {
    showSupportModal.value = state
  } else {
    showSupportModal.value = !showSupportModal.value
  }
}

async function signOutUser () {
  try {
    await $api.user.endKeycloakSession()
    await $sentry.flush()
    await signOut('keycloak')
  } catch (error) {
    //
  } finally {
    await signOut('keycloak')
  }
}

const logoLink = computed(() => {
  if (isServiceproviderUser) {
    if (serviceprovider.value.uuid) {
      return `/provider/${serviceprovider.value.uuid}/monitoring`
    }

    return '/'
  } else if (isAdminUser) {
    return '/admin/serviceprovider'
  } else {
    if (getCurrentClientUUID()) {
      return `/client/${getCurrentClientUUID()}/`
    }
    return '/'
  }
})

async function storeServiceprovider () {
  let providerUUID = session?.value?.user?.roles['x-hasura-user-org']

  if (!providerUUID || isAdminUser) {
    providerUUID = route.params?.providerId.toString()
  }

  await initializeServiceprovider(providerUUID)
}

</script>

<style scoped></style>
