<template>
  <ModalBase
    v-if="!(currentEnv.isSelfhosted || currentEnv.isDemoEnv)"
    :titel="titel"
    :show="show"
    :button-confirm-disabled="message.length === 0 || (phone.length === 0 && requestCallBack) || contactReason.length === 0"
    button-confirm-text="Senden"
    button-cancel-text="Schließen"
    class="w-full"
    @on-cancel="emit('onCancel')"
    @on-confirm="sendForm"
  >
    <template #icon>
      <BIconChatLeft />
    </template>

    <template #content>
      <div class="mx-auto max-w-3xl">
        <form
          ref="contact-form"
          class="grid grid-cols-2 gap-y-6 gap-x-4"
        >
          <SelectMenu v-model:selected="contactReason" label="Grund*" :options="contactReasonOptions" />

          <SelectMenu v-model:selected="priority" label="Priorität" :options="priorityOptions" />

          <div class="col-span-2 flex">
            <input
              v-model="requestCallBack"
              class="checkbox-standard mt-1"
              name="callback"
              type="checkbox"
              :value="requestCallBack"
            >
            <label class="block text-sm font-medium text-secondary-lighter cursor-pointer" @click="requestCallBack = !requestCallBack">
              Ich möchte zurückgerufen werden</label>
          </div>

          <div v-if="requestCallBack" class="col-span-2">
            <label for="phone" class="sr-only">Telefon</label>
            <input
              v-model="phone"
              type="text"
              name="phone"
              autocomplete="off"
              class="text-input-standard"
              placeholder="Telefon*"
            >
          </div>
          <div class="col-span-2">
            <label for="message" class="sr-only">Nachricht</label>
            <textarea
              v-model="message"
              name="message"
              rows="4"
              required
              class="text-input-standard"
              placeholder="Nachricht*"
            />
          </div>
          <p
            :class="[status.startsWith('Die Nachricht wurde erfolgreich gesendet') ? 'text-special-green-text' : 'text-red', 'col-span-2 text-sm']"
          >
            {{ status }}
          </p>
        </form>
      </div>
    </template>
  </ModalBase>
</template>
<script setup>
import { BIconChatLeft } from 'bootstrap-icons-vue'
import ModalBase from '~/components/shared/modals/ModalBase.vue'
import SelectMenu from '~/components/shared/basics/SelectMenu.vue'
const { $toast, $saas, $logger } = useNuxtApp()
const emit = defineEmits(['onOk', 'onCancel'])
const { currentUserSession, userOrganization, currentEnv } = useCurrentUserSession()

defineProps({
  show: {
    type: Boolean,
    required: true
  },
  titel: {
    type: String,
    required: true
  }
})

const phone = ref('')
const message = ref('')
const status = ref('')
const contactReason = ref('')
const priority = ref('')
const requestCallBack = ref(false)

const contactReasonOptions = [
  'Technischer Support', 'Feature Request', 'Vertragliches', 'Feedback', 'Anderes'
]
const priorityOptions = [
  'Niedrig', 'Mittel', 'Hoch', 'Sehr hoch'
]

function resetValues () {
  phone.value = ''
  message.value = ''
  requestCallBack.value = false
  contactReason.value = ''
  priority.value = ''
}

function areRequireValuesFilled () {
  if (message.value.length === 0 || (phone.value.length === 0 && requestCallBack.value) || contactReason.value.length === 0) {
    status.value = 'Bitte alle Pflichtfelder ausfüllen'
    return false
  }

  status.value = ''
  return true
}

async function sendForm () {
  if (currentEnv.isDemoEnv) {
    emit('onCancel')
    $toast.error('Demo Umgebung', 'Nachrichten können in der Demo Umgebung nicht gesendet werden')
    return
  }

  const preCheckSucceed = areRequireValuesFilled()

  if (preCheckSucceed) {
    try {
      const data = {
        firstname: currentUserSession?.value?.user.givenName,
        lastname: currentUserSession?.value?.user.familyName,
        company: userOrganization,
        email: currentUserSession?.value?.user.email,
        phone: phone.value,
        message: message.value,
        contactReason: contactReason.value,
        requestCallBack: requestCallBack.value,
        priority: priority.value
      }

      const res = await $saas.other.sendContactForm(data)

      if (res.data.message === 'Email sent') {
        status.value = 'Die Nachricht wurde erfolgreich gesendet! Wir melden uns so schnell wie möglich'
        resetValues()
      } else {
        status.value =
          'Es gab ein Problem beim Senden der Nachricht. Bitte versuchen Sie es erneut oder schreiben Sie an support@sec-auditor.com'
        $logger.error(`Error: ${res.data.message}`)
      }
    } catch (e) {
      status.value =
        'Es gab ein Problem beim Senden der Nachricht. Bitte versuchen Sie es erneut oder schreiben Sie an support@sec-auditor.com'
      $logger.error(e)
    }
  }
}

</script>
