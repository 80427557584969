import SessionData from '@sidebase/nuxt-auth/dist/types'
import { type Ref } from 'vue'
import { useState } from '#app'
import type { ModuleOptions } from 'nuxt/schema'
import type { Session } from 'types/next-auth'
import { type SecNotification } from '~/types/other'
import { getMainRole } from '~/utils/other'

export const useCurrentIoe = (): {
    currentIoe: Ref<any>;
    setCurrentIoe: (ioe: any) => void;
} => {
  const currentIoe = useState('currentIoe', () => {
  })

  const setCurrentIoe = (ioe: any): void => {
    currentIoe.value = ioe
  }

  return {
    currentIoe,
    setCurrentIoe
  }
}

export const useNotifications = (): {
    notifications: Ref<SecNotification[]>;
    addNotification: (notification: SecNotification) => void;
    removeNotification: (uuid: number) => void
} => {
  const notifications: Ref<SecNotification[]> = useState('notifications', () => [])

  const addNotification = (notification: SecNotification): void => {
    const newNotification = {
      ...notification,
      id: notifications.value.length + 1
    }

    // add to notification queue
    notifications.value.push(newNotification)

    // set timer to remove the notification
    setTimeout(function () {
      removeNotification(newNotification.id)
    }, newNotification.duration)
  }

  const removeNotification = (notificationId: number): void => {
    notifications.value = notifications.value.filter(
      n => n.id !== notificationId
    )
  }

  return {
    notifications,
    addNotification,
    removeNotification
  }
}

export const useIoeConfig = (): {
  ioeConfig: Ref<any>;
  setIoeConfig: (config: any) => void;
} => {
  const ioeConfig = useState('ioeConfig', () => {
  })

  const setIoeConfig = (config: any): void => {
    ioeConfig.value = config
  }

  return {
    ioeConfig,
    setIoeConfig
  }
}

export const useCurrentUserSession = (): {
  currentUserSession: Ref<Session | null | undefined>;
  mainRole: string;
  isInternalUser: boolean;
  currentEnv: { isDemoEnv: boolean; isNextEnv: boolean; isProdEnv: boolean; isSelfhosted: boolean };
  userOrganization: any;
  setCurrentUserSession: (s: { RefreshHandler: RefreshHandler; ModuleOptions: ModuleOptions }) => void;
  isServiceproviderUser: any;
  isAdminUser: any
} => {
  const { data } = useAuth()
  const currentUserSession = useState('useCurrentUser', () => data)

  const setCurrentUserSession = (s: typeof SessionData): void => {
    currentUserSession.value = s
  }

  const isServiceproviderUser = currentUserSession?.value?.user?.roles?.['x-hasura-allowed-roles'].includes(
    'serviceprovider'
  )

  const isAdminUser = currentUserSession?.value?.user?.roles?.['x-hasura-allowed-roles'].includes(
    'admin'
  )

  const envVars = useRuntimeConfig()
  const isDemoEnv = envVars.public.ENVIRONMENT === 'demo'
  const isNextEnv = envVars.public.ENVIRONMENT === 'next' || envVars.public.ENVIRONMENT === 'dev'
  const isProdEnv = envVars.public.ENVIRONMENT === 'prod'
  const isSelfhosted = envVars.public.ENVIRONMENT === 'selfhosted'

  const currentEnv = {
    isDemoEnv,
    isNextEnv,
    isProdEnv,
    isSelfhosted
  }

  const internalEmailDomains = ['sec-auditor.com', 'thomaskoscheck.de']
  const userEmailDomain = currentUserSession?.value?.user?.email?.split('@')[1] || ''
  const isInternalUser = internalEmailDomains.includes(userEmailDomain)

  // removes all extra roles to return one of the three main roles
  // client, serviceprovider or admin
  const mainRole = getMainRole(currentUserSession?.value?.user?.roles?.['x-hasura-allowed-roles'])
  const userOrganization = currentUserSession?.value?.user?.roles?.['x-hasura-user-org']

  return {
    currentUserSession,
    mainRole,
    isInternalUser,
    currentEnv,
    userOrganization,
    setCurrentUserSession,
    isServiceproviderUser,
    isAdminUser
  }
}
