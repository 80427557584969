import validate from "/usr/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45next_45usage_45global from "/usr/app/middleware/1.next-usage.global.ts";
import _2_45route_45protection_45global from "/usr/app/middleware/2.route-protection.global.ts";
import _3_45redirect_45general_45routes_45global from "/usr/app/middleware/3.redirect-general-routes.global.ts";
import manifest_45route_45rule from "/usr/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45next_45usage_45global,
  _2_45route_45protection_45global,
  _3_45redirect_45general_45routes_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sidebase-auth": () => import("/usr/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}