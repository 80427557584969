import { useStorage } from '@vueuse/core'
import { type Ref } from 'vue'

type Client = {
  client_id_pk: string;
  client_name: string;
  client_address: string;
  client_mode: string;
  client_initialized: boolean;
  client_license: {
    employeeCount: number;
    freeSeats: number;
    paymentDetailsRequired: boolean;
    subscriptionStatus: string;
  };
}

export const useCurrentClient = (): {
  initializeCurrentClient: (uuid: string) => Promise<void>;
  currentClient: Ref<Client, Client>;
  setCurrentClientUUID: (uuid: string) => void;
  getCurrentClientIdToSchema: () => string;
  getCurrentClientUUID: () => string
} => {
  const { $gql, $api, $logger } = useNuxtApp()
  const defaultResponse: Client = {
    client_id_pk: '',
    client_name: '',
    client_address: '',
    client_mode: '',
    client_initialized: false,
    client_license: {
      employeeCount: 0,
      freeSeats: 2,
      paymentDetailsRequired: false,
      subscriptionStatus: 'active'
    }
  }

  const currentClient = useState('currentClient', () => {
    return defaultResponse
  })

  const clients = useStorage('clients', () => {
    return [defaultResponse]
  }, sessionStorage, // invalidates on tab close
  { listenToStorageChanges: true })

  const setCurrentClient = (c: Client): void => {
    // check if already existing in array, if so, update, otherwise push
    const index = clients.value.findIndex(cc => cc.client_id_pk === c.client_id_pk)
    if (index > -1) {
      clients.value[index] = c
    } else {
      clients.value.push(c)
    }

    currentClient.value = c
  }

  // check if client properties are good, otherwise fetch
  function isClientGood (client: Client): boolean {
    if (!client) {
      return false
    }

    if (!Object.hasOwn(client, 'client_license')) {
      return false
    }
    if (client.client_license.subscriptionStatus !== 'active') {
      return false
    }
    if (client.client_license.paymentDetailsRequired) {
      return false
    }
    if (!client.client_initialized) {
      return false
    }

    return true
  }

  const initializeCurrentClient = async (uuid: string): Promise<void> => {
    // check if client with this uuid exists and license data is exiting, otherwwise fetch
    const index = clients.value.findIndex(cc => cc.client_id_pk === uuid)
    if (index > -1 && isClientGood(clients.value[index])) {
      setCurrentClient(clients.value[index])
      return
    }

    const currentClientQuery = `
      query client($clientUUID: uuid!) {
        tbl_client(where: { client_id_pk: { _eq: $clientUUID } }) {
          client_name
          client_id_pk
          client_mode
          client_address
          client_initialized
        }
      }
    `

    try {
      const { data } = await $gql.query(currentClientQuery, {
        clientUUID: uuid
      })

      let client
      if (data.tbl_client.length > 0 && data.tbl_client[0].client_initialized) {
        const res = await $api.client.getClientLicense(uuid)
        client = data.tbl_client[0]
        client.client_license = res.data
      } else {
        client = data.tbl_client[0]
      }

      setCurrentClient(client)
    } catch (e) {
      $logger.error(e)
    }
  }

  const setCurrentClientUUID = (uuid: string): void => {
    uuid = uuid.replace(/_/g, '-')

    // check if client with this uuid exists, otherwise create new empty
    const index = clients.value.findIndex(cc => cc.client_id_pk === uuid)
    if (index === -1) {
      const initClient = defaultResponse
      initClient.client_id_pk = uuid
      setCurrentClient(initClient)
    }
  }

  const getCurrentClientIdToSchema = (): string => {
    return currentClient.value.client_id_pk.replace(/-/g, '_')
  }

  const getCurrentClientUUID = (): string => {
    if (currentClient.value.client_id_pk) {
      return currentClient.value.client_id_pk
    } else {
      return ''
    }
  }

  return {
    initializeCurrentClient,
    currentClient,
    setCurrentClientUUID,
    getCurrentClientIdToSchema,
    getCurrentClientUUID
  }
}
