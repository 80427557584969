<template>
  <div class="h-full">
    <BaseLayout>
      <template #primarySection>
        <section
          aria-labelledby="primary-heading"
          class="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-secondary p-10"
        >
          <div v-if="!currentClient.client_initialized && !loading" class="container mx-auto">
            <ClientSimpleClientNotInitialized class="mt-20 w-1/2 mx-auto " />
          </div>
          <slot v-else-if="isLicenseValid && !loading" class="mt-12" />
          <SharedCustomNotification />
        </section>
      </template>

      <template #secondarySection>
        <aside class="hidden lg:order-first lg:block lg:flex-shrink-0">
          <div
            class="relative flex h-full w-auto max-w-[15rem] flex-col overflow-y-auto"
          >
            <ClientNavigation v-if="!loading" :client-mode="currentClient.client_mode" />
          </div>
        </aside>
      </template>
    </BaseLayout>
    <ErrorView
      v-if="errorDuringLoading"
      :error="globalError"
      :on-click-method="() => clearError({ redirect: '/' })"
    />
    <InfoModal
      text="Um fortzufahren sind Zahlungsdetails erforderlich"
      titel="Zahlungsdetails erforderlich"
      :show="showPaymentDetailsModal"
      button-confirm-text="Zahlungsdetails eingeben"
      button-cancel-text="Zurück"
      @on-confirm="redirectToStripePaymentDetails"
      @on-cancel="showPaymentDetailsModal = false; navigateTo('/')"
    />
  </div>
</template>

<script setup>
// without this import (or from #import) it does not work https://github.com/nuxt/nuxt/issues/14595
import { useRoute } from 'vue-router'
import BaseLayout from '~/components/shared/BaseLayout.vue'
import { AppException } from '~/utils/other'
import InfoModal from '~/components/shared/modals/InfoModal.vue'
import ErrorView from '~/components/shared/ErrorView.vue'

import { useClientTimezone } from '~/composables/timezone'
import { useCurrentUserSession } from '~/composables/states'
import { useCurrentClient } from '~/composables/currentClient'
import { useCurrentServiceprovider } from '~/composables/serviceprovider'

const { $logger, $toast, $gql, $saas, $sentry } = useNuxtApp()
const route = useRoute()

const { initializeCurrentClient, currentClient, setCurrentClientUUID, getCurrentClientIdToSchema } = useCurrentClient()
const { getClientTimezone } = useClientTimezone()
const globalError = ref({})
const { isAdminUser, currentEnv } = useCurrentUserSession()
const { serviceprovider } = useCurrentServiceprovider()

// license related
const loading = ref(true)
const errorDuringLoading = ref(false)
const showPaymentDetailsModal = ref(false)

const isLicenseValid = computed(() => {
  // only if client already exists
  if (!currentClient.value.client_initialized || loading.value) {
    return true
  }

  const currentStatus = currentClient.value.client_license?.subscriptionStatus || null

  if (currentClient.value.client_license?.paymentDetailsRequired && !(currentEnv.isSelfhosted || currentEnv.isDemoEnv)) {
    showPaymentDetailsModal.value = true
  }

  if (currentStatus && !currentEnv.isSelfhosted) {
    if (currentStatus === 'active' || currentStatus === 'trialing') {
      return true
    } else {
      showPaymentDetailsModal.value = true
      return false
    }
  }

  errorDuringLoading.value = true
  // globalError.value = { title: 'Api konnte nicht erreicht werden', message: 'Bitte versuchen Sie es später erneut.', code: 500 }

  return false
})

watch(() => route.params.clientId, async (newClientId, oldClientId) => {
  if (newClientId && newClientId !== oldClientId) {
    await initializeClient(newClientId)
  }
}, { immediate: true, deep: true })

// Watch for session_id changes (after stripe payment)
watch(() => route.params.session_id, async () => {
  if (route.params.clientId) {
    await initializeClient(route.params.clientId)
  }
})

// Watch for client initialization
watch(() => currentClient.value.client_initialized, async (isInitialized) => {
  if (isInitialized && route.params.clientId) {
    await initializeClient(route.params.clientId)
  }
})

async function initializeClient (clientId) {
  if (!clientId) {
    return
  }

  try {
    loading.value = true

    // get client id
    setCurrentClientUUID(clientId.toString())
    $sentry.setTag('client', clientId.toString())

    await setClientBaseData(clientId)

    if (currentClient.value.client_initialized) {
      loading.value = false
      if (errorDuringLoading.value) {
        return
      }

      checkCollectorFirstRun().catch(e => $logger.error(e))
      loading.value = false

      if (!isLicenseValid) {
        showPaymentDetailsModal.value = true
      }
    } else {
      loading.value = false
    }
  } catch (e) {
    errorDuringLoading.value = true
    isLicenseValid.value = false
    globalError.value = {
      title: 'Seite konnte nicht geladen werden',
      message: e.message,
      code: 500
    }

    if (e instanceof AppException) {
      $toast.error(e.title, e.message)
    } else {
      $toast.error('Fehler', e.message)
    }

    $logger.error(e)
  }
}

async function redirectToStripePaymentDetails () {
  try {
    if (currentEnv.isSelfhosted || currentEnv.isDemoEnv) {
      showPaymentDetailsModal.value = false
      return
    }
    const res = await $saas.serviceprovider.createCheckoutSession(serviceprovider.value.uuid)

    // redirect to checkout session
    window.location.href = res.data
    showPaymentDetailsModal.value = false
  } catch (e) {
    $logger.error(e)
    $toast.error('Fehler', 'Zahlungsdetails konnten nicht abgerufen werden')
  }
}

async function setClientBaseData (clientId) {
  try {
    await initializeCurrentClient(clientId)

    // view not allowed for serviceprovider
    if (currentClient.value.client_mode === 'quickcheck' && !isAdminUser) {
      // redirect to quickcheck page
      await navigateTo('/')
    }

    // set client timezone if forest config is available (only after first collector run) and not in browser storage
    // if not initialized, error because tbl_domain_config does not exist
    // fetch to initialize
    if (currentClient.value.client_initialized) {
      await getClientTimezone(clientId)
    }
  } catch (e) {
    $logger.error(e)
    globalError.value = {
      title: 'Fehler beim Abrufen der Daten',
      message: 'Bitte versuchen Sie es später erneut.',
      code: 500
    }
  }
}

// check if collector is installed, if not, show notification
async function checkCollectorFirstRun () {
  try {
    const isCollectorInstalledQuery = `
        {
          collector: s_${getCurrentClientIdToSchema()}_tbl_status_collector {
            version
          }
        }
      `
    const { data } = await $gql.query(isCollectorInstalledQuery)
    if (data.collector?.[0]?.version === null && isLicenseValid && !loading) {
      $toast.warn(
        'Collector nicht eingerichtet',
        'Mehr unter: https://sec-auditor.com/docs/'
      )
    }
  } catch (e) {
    $logger.error(e)
  }
}
</script>

<style></style>
