import axiosRetry from 'axios-retry'
import axios from 'axios'
import { defineNuxtPlugin } from 'nuxt/app'
import * as saasApi from '@sec-auditor/saas-api-sdk'
import * as apiSdk from '@sec-auditor/api-sdk/index'

export default defineNuxtPlugin(async (nuxtApp) => {
  try {
    const { data: session } = useAuth()
    const envVars = useRuntimeConfig()

    let accessToken = session?.value?.accessToken

    // if these are not provided, we need to sign in
    if (!accessToken) {
      const headers = useRequestHeaders(['cookie']) as HeadersInit
      const { data: newToken } = await useFetch('/api/auth/session', { headers })

      if (newToken.value) {
        accessToken = newToken.value?.accessToken
      }
    }

    // axios default setup
    const axiosInstance = axios.create({
      headers: {
        common: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    })

    axiosInstance.defaults.withCredentials = true

    // retry on 5xx response codes
    axiosRetry(axiosInstance, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay
    })

    // refresh token if backend returns 401 with delay
    // . only request 3 times
    // from: https://github.com/axios/axios/issues/934
    let count = 0
    axiosInstance.interceptors.response.use(null, async (error) => {
      if (
        error.config &&
        error.response &&
        error.response.status === 401 &&
        count < 3
      ) {
        count++

        const headers = useRequestHeaders(['cookie']) as HeadersInit
        const { data: newSession } = await useFetch('/api/auth/session', {
          headers
        })

        accessToken = newSession.value?.accessToken

        error.config.headers.Authorization = `Bearer ${newSession.value?.accessToken}`
        return axiosInstance.request(error.config)
      }

      count = 0

      // handle error in axios-retry
      return Promise.reject(error)
    })

    const client = new apiSdk.ClientApi(
      undefined,
      envVars.public.API_URL,
      axiosInstance
    )
    const serviceprovider = new apiSdk.ServiceproviderApi(
      undefined,
      envVars.public.API_URL,
      axiosInstance
    )
    const other = new apiSdk.OtherApi(
      undefined,
      envVars.public.API_URL,
      axiosInstance
    )
    const user = new apiSdk.UserApi(
      undefined,
      envVars.public.API_URL,
      axiosInstance
    )

    const admin = new apiSdk.AdminApi(
      undefined,
      envVars.public.API_URL,
      axiosInstance
    )

    const saas = {
      serviceprovider: new saasApi.ServiceproviderApi(undefined, envVars.public.SAAS_API_URL, axiosInstance),
      other: new saasApi.OtherApi(undefined, envVars.public.SAAS_API_URL, axiosInstance),
      user: new saasApi.UserApi(undefined, envVars.public.SAAS_API_URL, axiosInstance)
    }

    // Inject to context as $api
    const api = { client, serviceprovider, other, user, admin }
    return {
      provide: {
        api,
        saas,
        axiosInstance
      }
    }
  } catch (e) {
    nuxtApp.$logger.error(e)
  }
})
