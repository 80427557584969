<template>
  <div class="bg-secondary-dark h-full">
    <div>
      <div
        class="text-secondary-light mr-2 mt-2 cursor-pointer"
        @click="handleSidebarToggleClick"
      >
        <BIconArrowRightShort v-if="sidebarCollapsed" class="ml-auto w-5 h-5" />
        <BIconArrowLeftShort v-else class="ml-auto w-5 h-5" />
      </div>

      <div class="flex items-center px-4 pt-3 pb-6 w-auto overflow-hidden">
        <div
          class="flex overflow-hidden"
          :class="[sidebarCollapsed ? '' : 'space-x-3']"
        >
          <ServiceproviderLogo :small-icon="sidebarCollapsed" class="mx-auto" />

          <div class="flex flex-col my-auto">
            <p
              v-if="!sidebarCollapsed"
              class="text-common-white font-bold  truncate mt-1"
            >
              {{ serviceproviderName }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-grow flex-col">
        <nav class="flex-1 space-y-2 px-2 justify-center" aria-label="Sidebar">
          <NuxtLink
            v-for="n in nav"
            :key="n.to"
            :to="n.to"
            class="group flex justify-between py-2 px-2 text-md"
          >
            <component
              :is="n.icon"
              :class="[
                n.isActive
                  ? 'text-lilac'
                  : 'text-secondary-lighter group-hover:text-common-white',
                sidebarCollapsed ? 'my-auto mx-auto' : 'mr-3',
                'w-5 h-5 self-center',
              ]"
            />

            <span
              v-if="!sidebarCollapsed"
              :class="[
                n.isActive
                  ? 'text-lilac font-bold'
                  : 'text-secondary-lighter group-hover:text-common-white',
                'flex-1 ',
              ]"
            >{{ n.title }}</span>

            <span v-if="n.alerts > 0 && !sidebarCollapsed" class="bg-secondary-highlight-light text-common-white text-xs font-bold ml-2 px-2.5 py-1 rounded-full my-auto"> {{ n?.alerts }}</span>
          </NuxtLink>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  BIconArrowLeftShort,
  BIconArrowRightShort,
  BIconActivity,
  BIconGear,
  BIconCardChecklist,
  BIconClipboardData
} from 'bootstrap-icons-vue'

import ServiceproviderLogo from '~/components/serviceprovider/ServiceproviderLogo.vue'
import { useCurrentServiceprovider } from '~/composables/serviceprovider'
const route = useRoute()
const { userOrganization, isAdminUser } = useCurrentUserSession()
const { serviceprovider } = useCurrentServiceprovider()

// 1536 px is tailwind 2xl breakpoint for responsive design
const sidebarCollapsed = ref(window.innerWidth < 1536 || false)
const alerts = ref({
  dashboard: 0,
  changeHistory: 0,
  ioe: 0,
  ioa: 0,
  settings: 0
})

const serviceproviderName = computed(() => serviceprovider.value.name)

const nav = computed(() => {
  let uuid = userOrganization
  if (isAdminUser) {
    uuid = serviceprovider.value.uuid
  }

  return [
    {
      title: 'Monitoring',
      to: `/provider/${uuid}/monitoring`,
      icon: BIconActivity,
      isActive: route.path.includes('monitoring'),
      alerts: alerts.value.changeHistory
    },
    {
      title: 'Audit',
      to: `/provider/${uuid}/audit`,
      icon: BIconClipboardData,
      isActive: route.path.includes('audit'),
      alerts: alerts.value.ioe
    },
    {
      title: 'Quickcheck',
      to: `/provider/${uuid}/quickcheck`,
      icon: BIconCardChecklist,
      isActive: route.path.includes('quickcheck'),
      alerts: alerts.value.settings
    },
    {
      title: 'Einstellungen',
      to: `/provider/${uuid}/settings`,
      icon: BIconGear,
      isActive: route.path.includes('settings'),
      alerts: alerts.value.settings
    }
  ]
})

function handleSidebarToggleClick () {
  sidebarCollapsed.value = !sidebarCollapsed.value
}

</script>

<style scoped></style>
