import type { Ref } from 'vue'
import { useStorage } from '@vueuse/core'

type Serviceprovider = {
  uuid: string;
  name: string;
  freeSeats: number;
  hasLogo: boolean;
  paymentDetailsRequired: boolean;
  dataLoaded: boolean;
}

export const useCurrentServiceprovider = (): {
  serviceprovider: Ref<Serviceprovider, Serviceprovider>;
  getServiceproviderIdToSchema: () => string;
  initializeServiceprovider: (uuid: string) => Promise<void>;
} => {
  const { $api, $logger, $gql } = useNuxtApp()
  const serviceprovider: Ref<Serviceprovider> = useStorage('serviceprovider', () => {
    return { uuid: '', freeSeats: 2, name: '', hasLogo: false, dataLoaded: false, paymentDetailsRequired: false }
  }
  , sessionStorage, // invalidates on tab close
  { listenToStorageChanges: true })

  const initializeServiceprovider = async (uuid: string): Promise<void> => {
    // if serviceprovider does not have all required properties fetch it
    if (!isServiceproviderGood(uuid)) {
      const apiRes =
        await $api.serviceprovider.getServiceprovider(uuid)

      const currentSpQuery = computed(() => {
        return `query serviceprovider {
          sp: tbl_serviceprovider(where: {serviceprovider_id_pk: {_eq: "${uuid}"}}) {
            serviceprovider_name
          }
        }`
      })

      const { data } = await $gql.query(currentSpQuery.value)

      if (apiRes.data && data.sp.length > 0) {
        setServiceprovider({
          uuid,
          name: data?.sp?.[0]?.serviceprovider_name ?? '',
          freeSeats: apiRes.data.license?.freeSeats ?? 2,
          hasLogo: apiRes.data?.whitelabel?.hasLogo ?? false,
          paymentDetailsRequired: apiRes.data?.license?.paymentDetailsRequired ?? false,
          dataLoaded: true
        })
      } else {
        $logger.error(new Error('Serviceprovider not found'))
      }
    } else {
      $logger.info('Serviceprovider already loaded')
    }
  }

  // check if sp properties are good, otherwise fetch
  function isServiceproviderGood (newSpUUID: string): boolean {
    if (!serviceprovider.value) {
      return false
    }

    // required for admin when switching between serviceproviders
    if (serviceprovider.value.uuid !== newSpUUID) {
      return false
    }

    if (!Object.hasOwn(serviceprovider.value, 'uuid') || !Object.hasOwn(serviceprovider.value, 'paymentDetailsRequired') || !Object.hasOwn(serviceprovider.value, 'dataLoaded')) {
      return false
    }
    if (serviceprovider.value.name.length === 0) {
      return false
    }
    if (serviceprovider.value.paymentDetailsRequired) {
      return false
    }

    return true
  }

  const setServiceprovider = (sp: Serviceprovider): void => {
    // if already set, use the existing name, otherwise overwrite
    serviceprovider.value = {
      ...serviceprovider.value,
      ...sp,
      dataLoaded: true
    }
  }

  const getServiceproviderIdToSchema = (): string => {
    if (serviceprovider.value.uuid) {
      return serviceprovider.value.uuid.replace(/-/g, '_')
    } else {
      return ''
    }
  }

  return {
    serviceprovider,
    initializeServiceprovider,
    getServiceproviderIdToSchema
  }
}
